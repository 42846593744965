main {
  //overflow-x: hidden;
  //@media (max-width: 992px) {
  //  overflow-x: unset;
  //}
  position: relative;
  z-index: 5;

  @media (min-width: 1440px) {
    min-height: 85vh;
  }

  @media (max-width: 1439px) {
    min-height: unset;
    height: 100%;
  }

}

.SideNav {
  width: 310px;
  margin-right: 25px;
  display: block;

  @media (max-width: 1280px) {
    margin-right: 10px;
  }
}

.ContactUnderNav {

}

.main-content {
  width: 100%;

  @media (max-width: 480px) {
    padding-bottom: 75px;
  }
}

.mobileSettings {
  @media (max-width: 480px) {
    margin-top: -15px;
  }
}