.nav-link {
  a {
    //font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  font-size: 16px;
}