.nav {
  align-items: center;

  &-link {
    font-size: 16px;
    padding: 15px 20px;

    @media (max-width: 992px) {
      padding: 15px 25px;
    }
  }

  @media (max-width: 992px) {
    display: block !important;
  }
}

.mobile-only {
  display: none;
  @media (max-width: 992px){
    display: flex;
  }
}

.GDPR {
  position: relative;
  display: block;
  transition: all .2s linear;

  color: #1b1e21;
  a {
    color: #00b8f5 !important;
  }



  @media (max-width: 992px) {
    margin-right: 0 !important;
    height: 50px;
  }

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 30px 30px 30px;
    border-color: transparent transparent #ffffff transparent;
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 9999;
    visibility: hidden;

    @media (max-width: 992px) {
      display: none;
    }
  }

  .submenu {
    position: absolute;
    top: 0;
    padding-top: 50px;
    width: 350px;
    height: 30px;
    right: 0;
    z-index: -1;
    transition: height .4s ease;

    @media (max-width: 992px) {
      position: relative;
      padding-top: 25px;
      width: 100%;
      height: 0;
      //margin-bottom: 20px;
    }
  }

  .gdpr-documents {
    list-style: none;
    position: absolute;
    width: 400px;
    background: #fff;
    padding: 35px 35px 30px 35px;
    top: 65px;
    right: -20px;
    z-index: 999;
    border-radius: 10px;
    overflow: hidden;
    visibility: hidden;
    box-shadow: 0px 25px 60px rgba(0, 84, 112, 0.15);
    border-radius: 10px;
    transition: height .4s ease;

    //height: 0;

    @media (max-width: 992px) {
      position: relative;
      top: 0;
      right: 0;
      box-shadow: none;
      background: none;
      width: 100%;
      height: 0;
      padding: 0;
      padding-left: 10px;
    }

    li {
      position: relative;
      display: flex;
      margin-bottom: 25px;
      padding-bottom: 20px;
      border-bottom: 1px solid #CCDFE5;;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
      a {
       @media (max-width: 992px) {
         font-size: 14px;
         padding: 0;
       }
      }

      @media (max-width: 992px) {
        width: 100%;
        padding-bottom: 10px;

      }
    }

    li:before {
      content: "";
      background: url("../../../../assets/UserAssets/document.png") no-repeat;
      background-size: 20px;
      height: 26px;
      width: 26px;
      display: block;
      margin-right: 20px;

      @media (max-width: 992px) {
        //background-size: cover !important;
        height: 28px;
        width: 32px;
        margin-right: 10px;
        position: relative;
        z-index: 999;
      }

    }

  }

  @media (min-width: 992px) {
    &:hover {
      color: #00b8f5;
      .gdpr-documents {
        visibility: visible;
        display: block;
        //height: 300px;

        &:before {
          visibility: visible;
          opacity: 1;
        }
      }

      .submenu {
        z-index: 999;
      }
    }
  }
}

.GDPR:hover {
  &:before {
    visibility: visible;
    opacity: 1;
  }
}


.active {
  color: #00b8f5 !important;
}

.showCollapse {
  transition: all .2s linear;
  .gdpr-documents,
  .submenu {
    height: 100%;
    visibility: visible;
    z-index: 999;
  }

  .submenu {
    margin-bottom: 20px;
  }

  @media (max-width: 992px) {
    min-height: 250px;
    max-height: 300px;
  }
}

@media (max-width: 992px) {
  .GDPR.li-wrap {
    border-top: unset !important;
  }
}
