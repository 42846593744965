.Servis {
  margin-left: 25px;
  padding-left: 25px;
  border-left: 1px solid #CCDFE6;

  &-icon img {
    margin-right: 15px;
    display: block;
    height: 35px;
    position: relative;
    top: 3px;
  }

  &-title {
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #5A7984;
    margin-bottom: 5px;
  }

  &-number a {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #000000;

    @media (max-width: 1180px) {
      font-size: 15px;
    }
  }
}