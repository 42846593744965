.Logo {
  padding: 10px;
  position: relative;
  z-index: 1000;
  .home-link {
    display: block;
  }

  img {
    width: 240px;
    height: 42px;
    transition: width .2s linear;
    position: relative;
    z-index: 1000;
    top: -15px;

    @media (max-width: 1180px) {
      width: 150px;
    }

    @media (max-width: 1025px) and (min-width: 1024px) {
      width: 180px;
      position: relative;
      top: -8px;
    }

    @media (max-width: 768px) {
      width: 140px;
      top: 0;
    }
  }

  @media (max-width: 992px) {
    //margin-bottom: 40px;
    position: relative;
    top: -5px;
  }

  @media (max-width: 580px) {
    margin-bottom: 0;
  }
}


