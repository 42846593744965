.SideDrawer {
  position: fixed;
  width: 100%;
  //max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  padding-top: 90px;
  //background: radial-gradient(116.48% 209.06% at 80.47% 81.35%, #DFF7FF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
  background-color: #fff;
  //padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}



@media (min-width: 992px) {
  .SideDrawer {
    display: none;
  }
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}


@media (max-width: 992px) {
  .li-wrap {
    width: 100%;
    border-bottom: 1px solid rgba(128, 128, 128, .14);
    border-top: 1px solid rgba(128, 128, 128, .14);
    //color: gray;
  }
}