.header {
  position: relative;
  width: 100%;
  @media(max-width: 992px) {
    z-index: 210;
  }

  @media (max-width: 580px) {
    //margin-bottom: 20px;
  }

  .servis-wrap {
    @media (max-width: 1024px) {
      display: none;
    }
  }

  .DesktopOnly {
    display: block;
    @media (max-width: 992px) {
      display: none !important;
    }
  }

}